import { IconButton, Stack } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { ArrowLeftIconM, ArrowRightIconM } from '@carvertical/icons/react';
import { NS_CONTENT } from 'constants/i18n';

type NavigationProps = {
  onLeftClick: () => void;
  onRightClick: () => void;
};

const Navigation = ({ onLeftClick, onRightClick }: NavigationProps) => {
  const { t } = useTranslation(NS_CONTENT);

  return (
    <Stack gap={3} type="horizontal" mainAxisAlign="spaceBetween">
      <IconButton
        icon={ArrowLeftIconM}
        label={t('previousArticle')}
        variant="blue"
        size="m"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          onLeftClick();
        }}
      />

      <IconButton
        icon={ArrowRightIconM}
        label={t('nextArticle')}
        variant="blue"
        size="m"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          onRightClick();
        }}
      />
    </Stack>
  );
};

export { Navigation };
